import React, { createRef, useEffect, memo } from "react"
import lottie from "lottie-web"

import animationData from "../data/logo.json"

const AnimatedLogo = () => {
  let animationContainer = createRef()

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animationData,
    })
    return () => anim.destroy()
  }, [animationContainer, animationData])

  return <div className="logo" ref={animationContainer} />
}

export default memo(AnimatedLogo)
